import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

const LinkMap = {}

const storiesNews2023 = [
  {
    title: "1.Lots of Free Ebooks For Frontend Developers",
    postId: "1f50330930f",
  },
  {
    title: "2.How to Become a Frontend Master?",
    postId: "25d8b300cf96",
  },
  {
    title: "3.Simple to Complex Frontend Applications",
    postId: "1e01dc6e98a7",
  },
  {
    title: "4.What are React Hooks?",
    postId: "73af1dcd898c",
  },
  {
    title: "5.Overcoming Technological Change and Pressure",
    postId: "7a915b4661fd",
  },
  {
    title: "6.The Development Of JavaScript Through Time",
    postId: "f8491ffe6b41",
  },
  {
    title: "7.Fundamental Internet Concepts",
    postId: "47f5551824a9",
  },
  {
    title: "8.Building a Remember Me Page",
    postId: "eab9c747f44a",
  },
  {
    title: "9.Mastering React Client State Management",
    postId: "b40afc51ca31",
  },
  {
    title: "10.Lets Develop A Simple Calculator",
    postId: "f2cedf05db25",
  },
  {
    title: "11.JavaScript Foundations",
    postId: "ea1b508381f9",
  },
  {
    title: "12.Logical Model and Concepts for React Applications",
    postId: "7697f1e63723",
  },
  {
    title: "13.How to control re-renders on React Class Components",
    postId: "d599b5285939",
  },
]

const storiesImprovements = [
  {
    title: "LearnReactUI.dev ve Demolar",
    postId: "d8d6677affce",
  },
  {
    title: "Improve EBook Quality",
    postId: "9f31393f79f9",
  },
  {
    title: "Content Infrastructure",
    postId: "3a8aa57e4d74",
    postIdEnglish: "7e18397a12b7",
  },
]

const storiesGroup = [
  {
    title: "2023 Updates",
    storyPart: storiesNews2023,
  },
  {
    title: "Improvements",
    storyPart: storiesImprovements,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "Learn React UI Development Updates",
  path: "learn-react-ui-dev-news",
}

const LearnReactUIDevNewsPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default LearnReactUIDevNewsPage
